@import "mixins/banner";
@include bsBanner("");


// scss-docs-start import-stack
// Configuration
@import "functions";
@import "variables";
@import "maps";
@import "mixins";
@import "utilities";

// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "nav";
@import "navbar";
@import "card";
@import "accordion";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "alert";
@import "progress";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "offcanvas";
@import "placeholders";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack

/* site styles */
:root {
  --primary-color: #faf800;
  --secondary-color: #314298;
  --tertiary-color: #fd0000;
  --gray: #414141;
}

body {
  overflow-x: hidden;
}

.cta.send-btn {
  padding: 20px 0;
  border: none;
  background: none;
}

button:focus, button:active {
  outline: none
}

img {
  max-width: 100%;
}


h1, h2, h3, h4, h5, h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 800
}

a {
  color: #31409b;
  color: var(--secondary-color);
  text-decoration: none;
}

a:hover {
  color: #fd0000;
}

.centeredText {
  text-align: center
}

.accent-primary {
  color: #faf800;
  color: var(--primary-color)
}

.accent-secondary {
  color: #31409b;
  color: var(--secondary-color)
}

.accent-tertiary {
  color: #fd0000;
  color: var(--tertiary-color)
}

.whiteText {
  color: #fff
}

.textSpaceMedium {
  letter-spacing: 7px;
}

.textSpaceWide {
  letter-spacing: 21px;
}

.flipped-y {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}

.dobbs_scheduler {
  position: fixed;
  bottom: 15px;
  right: 15px;

  .icon_animate {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    position: relative;
    z-index:2;
  }

  .icon_border {
    position: absolute;
    width: 100%;
    height: 100%;
    background: conic-gradient(white 30%, transparent 30% 33.33%, white 33.33% 63%, transparent 63% 66.66%, white 66.66% 96%, transparent 97% 100%);
    border-radius: 50px;
    z-index: -1;
    transform: rotate(0deg);
    transition: transform .7s ease-in-out;
  }

  :hover .icon_border {
      transform: rotate(360deg)
  }

  .icon-cover {
    position: absolute;
    width: 87%;
    height: 87%;
    background: var(--tertiary-color);
    border-radius: 50px;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-51%, -51%);
  }
}

@keyframes floating {
  0%, 80% {
    transform: translateY(0)
  }
  100% {
    transform:translateY(-20px);
  }
}

.scheduleBtn {
  border: none;
  background: var(--tertiary-color);
  box-shadow: 0 4px 11px #00000054;
  color: #fff;
  padding: 15px 30px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  transition: all .3s ease;
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  gap:10px;
  animation-name: floating;
  animation-duration: 7s;
  animation-timing-function: linear(0, 0.238 2%, 0.43 3.8%, 0.596 5.6%, 0.725 7.3%, 0.825 9%, 0.892 10.6%, 0.916 11.4%, 0.935 12.3%, 0.945 13.1%, 0.949 14%, 0.948 14.7%, 0.94 15.5%, 0.927 16.3%, 0.909 17.1%, 0.859 18.7%, 0.783 20.5%, 0.611 23.7%, 0.133 31.5%, 0.016 33.7%, -0.076 35.7%, -0.145 37.5%, -0.2 39.3%, -0.241 41.1%, -0.257 42%, -0.27 43%, -0.282 44.6%, -0.285 45.4%, -0.285 46.2%, -0.284 47%, -0.28 47.9%, -0.264 49.8%, -0.245 51.4%, -0.22 53.1%, -0.098 60.2%, -0.049 63.5%, -0.027 65.3%, -0.008 67.1%, 0.006 68.9%, 0.018 70.8%, 0.024 72.3%, 0.027 73.8%, 0.029 75.4%, 0.029 77.2%, 0.025 80.2%, 0.01 87.1%, 0.004 90.7%, 0.001 94.8%, 0);
  animation-iteration-count: 3;

  .st-widget-body & {
    animation:none
  }

  i, svg {
    display: inline-block;
    margin-top: -2px;
    margin-left: 0px;
  }

}

.titleScheduler .icon_animate {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
}

/* general section styles */
#pileContainer {
  height: 100%;
  position: relative;
  width: 100%;
}

.backgroundMedia {
  position: relative;
}

.sectionContent {
  padding: 65px 0;
}

.sectionPadding--sm {
  padding: 20px 0
}

.sectionPadding--lg {
  padding: 60px 0;
}

.sectionBg {
  background-position: center;
  background-size: cover;
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: -5;
  top:0;

  img {
    max-width: unset;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
  }

  &.parallaxBG img {
    left: 50%;
    top: 25%;
    transform: translate(-50%, -50%);
  }

  &.bgFixed img {
    height: 100%;
    object-fit: cover;
  }
}

.gridImage {
  margin-bottom: var(--bs-gutter-x);
  filter: drop-shadow(4px 4px 4px #00000038);
}

.subpageBanner .container {
  padding: 200px 0 245px
}

.landingBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0;
}

@media (max-width: 768px) {
  .landingBanner {
    flex-direction: column;
    align-items: flex-start;
  }
}

.landing_cta {
  font-size: 1.1rem;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  margin-bottom: 0;
  max-width: 570px;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.cta_bear {
  width: 189px;
  position: absolute;
  top: -15px;
  right: -25px;
}

.cta-text {
  background: var(--secondary-color);
  border-bottom: 10px solid var(--primary-color);
  padding: 10px 153px 10px 20px;
  line-height: 2.5rem;
}

.bgOverlay:before {
  background: rgba(0, 0, 0, .2);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.bgOverlay--darkGray:before {
  background: rgba(41, 41, 41, .8);
}

.bgOverlay--dark:before {
  background: rgba(0, 0, 0, .8);
}

.bgOverlay--blue:before {
  background: rgba(49, 64, 155, .9);
}

.bgOverlay--yellow:before {
  background: rgba(250, 248, 0, .9);
}

.bgColorPrimary {
  background-color: var(--primary-color);
}

.bgSecondary {
  background-color: var(--secondary-color);
}

.bgColorTertiary {
  background-color: var(--tertiary-color);
}

.bgGradientPrimary {
  background: linear-gradient(to top, #FAF800, white);
}

.sectionTitle {
  margin-bottom: 50px;
}

.sectionTitle span {
  font-size: 3.7rem;
}

.sectionTitle h3 {
  font-size: 3rem;
}

.graphicContainer {
  position: relative;
}

.graphicAccent {
  height: 100%;
  opacity: .2;
  position: absolute;
  right: 0;
  top: -121px;
  width: 700px;
  z-index: -1;
}

.containedSection {
  overflow: hidden
}

.relative {
  position: relative;
}

.borderedSection {
  border-bottom: 30px solid #faf800;
  border-top: 30px solid #faf800;
}

.borderedSection--top {
  border-top: 30px solid #faf800;
}

.pt-40 {
  padding-top: 40px
}

.scrollSection {
  box-shadow: 0 0 17px rgba(0, 0, 0, .7);
  height: 100vh;
  position: absolute;
  width: 100%;
  will-change: transform;
  z-index: 10;
}

@media (max-width: 992px) {
  .scrollSection {
    height:auto;
  }
}

.no-es6object .scrollSection {
  position: relative
}

.overflowHidden {
  overflow: hidden
}

/* general section styles */

/* Header Styles */
.site-header {
  border: 0;
  box-shadow: none;
  font-family: 'Roboto', sans-serif;
  height: auto;
  position: relative;
  width: 100%;
  z-index: 100;
}


.site-header.subpage {
  position: relative;
}

.site-header-wrapper {
  background: #faf800;
  background: var(--primary-color);
}

.site-header-main-wrapper {
  box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 1;
}

.siteheader-container {
  position: relative;
}

.site-header .logosize--contain .site-logo a {
  height: 70px;
}

.site-header .separator {
  background: #fff;
  height: 1px;
  margin-bottom: 5px;
  opacity: .2;
}

.site-header .kl-header-bg {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, transparent 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=0);
  height: 170%;
  left: 0;
  opacity: .6;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.site-logo {
  display: inline-block;
  margin-bottom: 0;
  padding: 15px 0;
}

.site-logo img {
  height: 95px;
  max-width: 100%;
}

.site-header .site-header-top {
  height: 46px;
}

.site-header .site-header-top-wrapper {
  position: relative;
}

.site-header-main-center {
  margin-left: 30px;
}

.site-header .searchBtn.active {
  border-radius: 2px 2px 0 0;
}

.site-header .kl-header-toptext {
  color: #414141;
  color: var(--gray);
  font-size: 0.95rem;
  line-height: 2.2;
}

.site-header .kl-header-toptext a {
  color: #fff;
  font-size: 1rem;
}

.site-header .site-header-top-wrapper .topnav {
  z-index: 111;
}

.site-header .site-header-top-wrapper .topnav.topnav--lang {
  z-index: 112;
}

.site-header .social-icons.topnav {
  margin: 0;
  padding: 0;
  position: relative;
}

.site-header .social-icons.topnav li {
  margin: 0;
  margin-left: 10px;
}

.site-header .social-icons.topnav.sc--clean li {
  margin-left: 0;
}

.site-header .social-icons.topnav li:first-child {
  margin-left: 0;
}

.site-header .kl-header-toptext {
  margin-left: 20px;
}

.site-header .kl-cta-lined {
  margin-top: 13px;
}

.site-header .site-logo-img {
  width: auto;
}

.site-header .logo-container {
  height: 100%;
  min-width: 140px;
}

.site-header .site-logo-img {
  max-height: 90px;
}

.headerphone {
  color: #414141;
  color: var(--gray);
  font-family: 'Poppins', sans-serif;
  font-size: clamp(27px, 2vw, 33px);
  font-weight: 900;
  letter-spacing: -1px;
}

.subpage-title span {
  color: #ffffff;
  text-shadow: 2px 2px 3px #000;
  font-size: 3.5rem;
  font-weight: 900;
  line-height: 1em;
  margin-bottom: 15px;
  position: relative;
  text-transform: uppercase;
}

.subpage-title {
  align-items: center;
  display: flex;
  height: 100%;
}

/* Call to action Button in Header */
.siteheader-schedule {
  position: relative;
}

.quote-ribbon {
  position: absolute;
  right: 0;
  top: 100%;
}

.ctaTitle {
  color: #fff;
  display: block;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 12px 30px 12px;
  position: relative;
}

.ctaTitle svg {
  margin-right: 8px;
}

.ctaTitle span {
  position: relative;
  letter-spacing: initial;
}

.kl-cta-ribbon strong {
  display: block;
  font-size: 17px;
  font-weight: 900;
  line-height: 24px;
}

.kl-cta-lined {
  background-color: transparent;
  box-shadow: none;
  margin-top: 5px;
  padding: 8px 20px 9px;
}

.main-menu-wrapper {
  width: 100%;
  height: 100%;
}

.main-nav {
  display: none;
}

.quote-ribbon .send-btn, .referralForm .send-btn {
  color: #fff;
}


/* homepage animation related styles */

.home #pageWrapper {
  perspective: 1000px;
  transform-style: preserve-3d;
  overflow: hidden;
}

#homeBody {
  transform-origin: center top
}

.waveCurve {
  fill: transparent;
  stroke: #fff;
  stroke-width: 0;
}

.no-es6object .waveCurve {
  fill: #fff;
  stroke: #fff;
  stroke-width: 1px;
}

.parallaxBgImg {
  max-width: unset;
  position: absolute;
  top: 0;
  transform: translateY(20%);
  width: 120%;
}

/* homepage animation related styles */
/* Hero Styles */

.hero {
  display: flex;
  flex-direction: column;
  height: auto;
}

.subpageBanner {
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
  min-height: 500px;
}

.heroBg {
  position: relative;
  width: 100%;
  z-index: -5;
  min-height: 650px;
  height: calc(100vh - 125px);
  display: flex;
  overflow: hidden;
  background: #fff;

  video {
    width: 100%;
    object-fit: cover;
    z-index: 2;
    height: 100%;
  }
}

video-js {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

#heroIframe {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s 1.3s;

  &.ready {
    opacity: 1;
    visibility: visible;
  }
}

.vidPlacholder {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

:root {
  --video-width: 100vw;
  --video-height: 100vh;
}

@media (min-aspect-ratio: 16/9) {
  :root {
    --video-height: 56.25vw;
  }
}

@media (max-aspect-ratio: 16/9) {
  :root {
    --video-width: 177.78vh;
  }
}

.heroBg iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--video-width);
  height: var(--video-height);
  transform: translate(-50%, -50%);
}

.heroContentContainer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heroContent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  //flex-wrap: wrap;
}

.heroContent h1 {
  color: #f3f3f3;
  font-weight: 600;
  font-size: clamp(1.2rem, 5vw, 3rem);
  text-shadow: 2px 2px 3px #000000;
  margin-bottom: 40px;
}

/* Van Banner Styles */
.vanBanner {
  background: #faf800;
  background: var(--primary-color);
  box-shadow: 0 0 20px -3px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: -1;
}

.vanBannerContent {
  display: inline-block;
  position: relative;
  width: 100%;
}

.vanBannerTag h4 {
  font-size: 2rem;
  font-size: clamp(.8rem, 1.7vw, 2.5rem);
  letter-spacing: .5px;
  margin-bottom: 0;
  padding-right: 20%;
  text-align: center;
  width: 100%;
}

.vanBannerTag {
  background: #414141;
  background: var(--gray);
  -webkit-clip-path: inset(-40px 113% -40px -153px);
  clip-path: inset(-40px 113% -40px -153px);
  color: #fff;
  filter: drop-shadow(-2px 4px 5px rgba(0, 0, 0, .3));
  height: 80px;
  margin-left: 100px;
  padding: 20px;
  position: relative;
  transform: translateY(-45px);
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vanBannerVan {
  position: absolute;
  right: 17px;
  top: -77px;
  transform: translateX(-100vw);
  width: 346px;
}

.vanBannerTag:before {
  border-bottom-color: #414141;
  border-bottom-color: var(--gray);
  border-left-color: transparent;
  border-right-color: #414141;
  border-right-color: var(--gray);
  border-style: solid;
  border-top-color: transparent;
  border-width: 40px;
  box-shadow: -43px 21px 23px -31px rgba(0, 0, 0, .42);
  content: "";
  position: absolute;
  right: 100%;
  top: 0;
}

.vanBannerTag:after {
  background: #faf800;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: skewX(-45deg) translateX(-79px);
  width: 40px;
}

.vanWheel {
  transform-origin: center
}

.tagBorder {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.74902) 50%, transparent);
  height: 2px;
  opacity: 0;
  position: absolute;
  width: 100px;
  z-index: 10;
}

.tb--top {
  left: -39px;
  top: 0
}

.tb--bottom {
  bottom: 0;
  left: -118px;
}

/** Subpage Styles */
.inner-content h4 {
  font-weight: 600;
  overflow: hidden;
  padding-bottom: 8px;
}

.inner-content h4 a {
  position: relative;
}

.inner-content h4 a:before {
  background: #fd0000;
  background: var(--tertiary-color);
  bottom: -5px;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  transform: translateX(-100%);
  transition: transform .3s;
  width: 100%;
}

.inner-content h4 a:hover:before {
  transform: translateX(0%);
}

.link-arrow-right svg {
  font-size: 19px;
  margin-left: 10px;
  transform: translateX(0);
  transition: transform .3s;
}

.inner-content h4 a:hover svg {
  transform: translateX(4px);
}

.sidebar .widget {
  background: #f5f5f5;
  border-radius: 3px;
  bottom: 40px;
  box-shadow: 0 8px 15px -7px rgba(0, 0, 0, 0.5);
  margin-bottom: 35px;
  padding: 25px 30px;
  position: relative;
}

.sidebar-bear {
  margin: auto;
  width: 150px;
}

/* Form Styles */
.contact-form input, .contact-form textarea {
  background: #f3f3f3;
  border: none;
  border-bottom: 3px solid transparent
}

.grecaptcha-badge {
  opacity:0;
  position:absolute;
  pointer-events: none;
}

.captchaInfo {
  display:none
}

.captchaInfo a {
  color: #fff;
  text-decoration: underline;
}
.captchaInfo {
  font-size: .8rem;
  color: #fff;
}

.formMessages {
  background: transparent;
  color: #333333;
  display: inline-block;
  width: 100%;
}

.formMessages ul {
  margin: 0;
  padding: 0 20px 0 35px;
}

.formMessages ul:first-child {
  margin-top: 15px;
}

.formMessages ul:last-child {
  margin-bottom: 15px;
}

.contact-form .formError {
  border: none;
  border-bottom: 3px solid #ff4d4d;
}

.formResult {
  font-size: 15px;
  margin: 20px 0 0 0;
  padding: 10px 0;
  text-align: center;
}

.formSuccess {
  border: 2px solid #568f43;
}

.formFail {
  border: 2px solid #ff4d4d;
}

.scheduleNow .inputContainer {
  padding: 20px 30px;
}

.heroText {
  width: 60%;
  padding-right: 50px;
}

.scheduleNow {
  width: 350px;
  max-width: 350px;
  z-index: 10;
  background: rgba(49, 64, 155, .85);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.6);
  padding: 30px 10px;
  border-radius: 5px;
}

.subpageBanner .scheduleNow {
  padding: 15px 10px;
  margin: 20px 0 50px auto;
}

@media (max-width: 991px) {
  .subpageBanner .scheduleNow {
    margin: 20px auto 50px;
  }
}

.scheduleNow input {
  background: #fff;
  border: none;
  color: #414141;
  display: block;
  margin-bottom: 15px;
  padding: 5px;
  width: 100%;
  border-radius: 5px;
}

.scheduleNow input:-ms-input-placeholder {
  color: #414141;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.scheduleNow input::-moz-placeholder {
  color: #414141;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.scheduleNow input::placeholder {
  color: #414141;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.scheduleNow .formResult:first-child {
  margin-top: 0
}

.formField input, .formField textarea {
  width: 100%;
}

#contact-form .formError {
  color: #fff;
  margin: 0 30px;
  border: 2px solid red;
  padding: 5px 15px;
}

#referralForm .formError {
  margin-top: 15px;
  border: 2px solid red;
  padding: 10px 15px 15px;
}

.formSuccess {
  border: 2px solid #568f43;
}
.formFail {
  border: 2px solid #ff4d4d;
}

.btnMainText {
  opacity: 1;
  transition: opacity .2s;
}
.btnProcessing {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity .3s;
}
.processing .btnMainText {
  opacity: 0;
}
.processing .btnProcessing {
  opacity: 1;
}
.grecaptcha-badge {
  visibility: hidden;
}
.captchaText {
  margin-top:15px;
}

.inputContainer .invalid-label {
  color: #fff;
  top: -16px;
  border-bottom: 1px solid red;
  display: inline-block;
  position: relative;
}

.formField.has-danger input {
  border-bottom-color: #f00;
}

.formField.has-danger {
  position: relative;
}

.formField.has-danger:before {
  color: #fd0101;
  content: "!";
  font-size: 1.4rem;
  left: -8px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.inputContainer.referralForm {
  box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #eaeaea;
  padding: 45px;
}

.referralForm input, .referralForm textarea {
  border: none;
  border-bottom: 1px solid #999;
  margin-bottom: 20px;
  padding: 15px;
}

.referralForm button {
  background: #31409b;
  background: var(--secondary-color);
  border: none;
  border-bottom: 3px solid #31409b;
  border-bottom: 3px solid var(--secondary-color);
  padding: 10px 21px 5px;
}

/* Form Styles */

/* imgBox Styles */
.imgBox {
  display: block;
  height: 320px;
  overflow: hidden;
  position: relative;
}

.imgBoxContainer {
  height: 100%;
  width: 30%;
}

.imgBox_content {
  align-items: center;
  background: rgba(49, 64, 155, .96);
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 20px;
  perspective: 1000px;
  position: absolute;
  text-align: center;
  top: 100%;
  transform: translateY(-77px) translateZ(0px);
  transform-style: preserve-3d;
  transition: transform .5s;
  width: 100%;
}

.imgBox_3d:hover .imgBox_content {
  box-shadow: 0 0 7px #000;
  transform: translateY(-100%) translateZ(-100px);
  -webkit-transform: translateY(-100%);
}

.imgBox_content h4 {
  transform: translateY(-88px);
  transition: transform .5s;
}

.imgBox_3d:hover .imgBox_content h4 {
  transform: translateY(0px);
}

.imgBox_img {
  background-position: center;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scale(1);
  transition: transform .3s;
  width: 100%
}

.imgBox_3d:hover .imgBox_img {
  transform: scale(1.2);
}

.imbox_raisedContent {
  padding: 15px;
  transform: translateZ(100px);
  -moz-transform: none;
  transform-style: preserve-3d;
}

.imgBox_img img {
  max-width: unset;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
}

.imgBox_3d {
  perspective: 1000px;
  -moz-perspective: none;
  perspective-origin: center;
}

/* imgBox Styles */

/* coupon styles */
.coupon {
  display: flex;
  position: relative;
  text-align: center;
  margin-bottom: var(--bs-gutter-x);

  img {
    width: 100%;
    filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.61961));
  }

  picture {
    max-width: 100%;
    height: 300px
  }
}

@include media-breakpoint-between(lg, xl) {
  .coupon picture {
    height: 230px;
  }
}

@media (max-width: 550px) {
  .coupon picture {
    height: 200px;
  }
}

.couponBg {
  background: linear-gradient(150deg, #31409b 50%, #4e4ecc 50%);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.couponLeft {
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 20px 50px 20px 20px;
  width: 60%;
}

.couponRight {
  background: #fff;
  box-sizing: content-box;
  filter: drop-shadow(0px 0px 3px black);
  padding: 20px 22px 20px 0;
  position: relative;
  width: 40%;
}

.couponRight:before {
  background: url(../img/graphics/coupon_clipping.png);
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  content: "";
  left: -44px;
  position: absolute;
  top: 0;
  width: 93px;
  z-index: -1;
}

.couponName {
  font-size: 2.7rem;
  font-weight: 700;
}

.subtext {
  display: block;
  font-size: 1.2rem;
  font-weight: 300;
}

.couponDetails {
  font-size: .8rem;
  margin-top: 17px;
}

/* coupon styles */

/* synchrony section homepage */
.financingWrapper {
  display:grid;
}

.financingImg a {
  display: flex;
  width: 100%;
  height: 100%;
  box-shadow: 4px 4px 10px #00000030;
  transition: transform .4s;

  &:hover {
    transform: scale(1.07)
  }
}
.financingQR img {
  box-shadow: -4px 4px 10px #00000030;
  height:auto;
}

@media (min-width: 1200px) {
  .financingWrapper {
    grid-template-columns:250px 1fr 250px;
  }
  .financingImg {
    justify-content: end;
  }
}
@media (max-width:1199px) {
  .financingWrapper {
    display:grid;
    grid-template-columns: 50% 50%;
  }
  .financingText {
    grid-column-end: span 2;
    margin-bottom: 30px;
  }
  .financingQR {
    justify-content: center;
    padding:10px;
  }
  .financingImg {
    padding:10px;
    justify-content: center;

    a {
      width:250px;
    }
  }
}

/* Carousel Styles */
.carousel {
  overflow: hidden;
}

.embla__container {
  display: flex;
}

.embla__slide {
  min-width: 100%;
  position: relative;
}

.testimonial {
  text-align: center;
  padding: 40px 20px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.testimonialAuthor {
  font-weight: 500;
}

.testimonialQuote {
  font-size: 1rem;
  padding: 25px 0;
  font-weight: 400;
}

.starGraphic {
  text-align: center;
}

.starGraphic img {
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, .2));
  max-height: 120px;
  margin: 10px 0;
}

.testimonial .starGraphic img {
  width: 200px;
}

.carouselDots {
  margin-top: 13px;
  text-align: center;
}

.embla__dot {
  background: #fff;
  border: none;
  border-radius: 3px;
  display: inline-block;
  height: 5px;
  margin-right: 10px;
  padding: 0;
  position: relative;
  width: 33px;
}

button.embla__dot.is-selected {
  background: #31409b;
  background: var(--secondary-color);
}

/* Carousel Styles */

/* icon box content */
.iconBox {
  align-items: center;
  display: flex;
}

.stackedicons {
  position: relative;
}

.iconBox svg {
  fill: #fff;
  width: 70px;
}

.iconBoxText {
  display: inline-block;
  font-family: "Poppins";
  font-size: 2rem;
  font-weight: 700;
  padding-left: 15px;
}

.topIcon {
  height: 44%;
  left: -30px;
  position: absolute;
  top: 8px;
}

.iconBoxContainer {
  margin-top: 120px;
}

/* icon box content */

/* rheem section styles */
.rheemCompressor {
  max-width: 40vw;
  transform: translateY(100px);
}

/* rheem section styles */

/* footer styles */
footer .sectionContent {
  padding: 50px 16px 20px
}

.footerLogo {
  margin-bottom: 25px;
}

.footerTitle {
  font-size: 1.3rem;
  margin-bottom: 25px;
}

.footerBear img {
  bottom: 12px;
  position: absolute;
  width: 16%;
  z-index: -3;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}

.footerList a {
  color: #fff;
  font-weight: 500
}

.footerList {
  padding-left: 0;
  list-style: none;
}

.contactList li {
  margin-bottom: 15px;
}

.social-icons a {
  font-size: 22px;
  margin-right: 10px;
}

.social-icons li {
  display: inline-block;
}

.footerCopyright {
  padding: 15px 5px;
  background: #414141;
  background: var(--gray);
}

.footerCopyright p {
  margin-bottom: 0;
}

.footerCopyright a {
  color: #fff
}

.divider-vertical {
  padding: 0 10px;
}

/* footer styles */

@media (min-width: 1400px) {
  .main-nav > ul > li > a {
    font-size: 1.15rem
  }

  .site-header-main-left {
    min-width: 230px;
    width: 12%;
  }

  .site-header-main-center {
    margin-left: 30px;
    width: 60%
  }

  .site-header-main-right {
    min-width: 230px;
    width: 25%;
  }
}

@media (max-width: 1399.9px) and (min-width: 992px) {
  .main-nav > ul > li > a {
    font-size: 15px;
  }
}

@media (max-width: 1399.9px) and (min-width: 1200px) {
  .site-header-main-left {
    min-width: 230px;
    width: 20%;
  }

  .site-header-main-center {
    margin-left: 30px;
    width: 50%
  }

  .site-header-main-right {
    min-width: 230px;
    width: 30%; 
  }
}

@media (max-width: 1199.9px) and (min-width: 992px) {
  .site-header-main-center {
    margin-left: 30px;
  }
  .site-header-main-left {
    min-width: 180px;
    width: 18%;
  }

  .site-header-main-right {
    min-width: 215px;
    width: 27%;
    justify-content: center !important;
  }
}

@media (max-width: 1199.9px) and (min-width: 992px) {
  .vanBannerTag h4 {
    font-size: 1rem;
    font-size: clamp(.8rem, 1.7vw, 2.5rem);
    line-height: 2.3rem;
  }
}

@media (max-width: 1199.9px) {
  .hideUnderXl {
    display: none
  }
  footer {
    text-align: center
  }
}

@media (min-width: 992px) {

  .main-nav {
    display: block;
    height: 100%;
  }

  .main-nav ul {
    line-height: 1;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .main-nav > ul {
    display: flex;
    justify-content: space-between;
    height: 100%;
  }

  .main-nav > ul:after,
  .main-nav > ul:before {
    content: " ";
    display: table
  }

  .main-nav > ul:after {
    clear: both
  }

  .main-nav ul li {
    margin-bottom: 0;
  }

  .main-nav ul li a {
    display: block;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  }

  /* FIRST LEVEL */
  .main-nav > ul > li {
    float: left;
    margin-left: 5px;
    margin-top: 0;
  }

  .main-nav > ul > li:not(.menu-item-mega-parent) {
    position: relative;
  }

  .main-nav > ul > li:first-child {
    margin-left: 0;
  }

  .main-nav > ul > li > a {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    color: #414141;
    color: var(--gray);
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 14px;
    padding: 6px 12px;
    position: relative;
    transition: transform .2s ease-in-out;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    height: 100%;
  }

  .main-nav > ul > li.active > a,
  .main-nav > ul > li > a:hover,
  .main-nav > ul > li:hover > a {
    color: var(--tertiary-color);
    text-decoration: none;
  }

  .main-nav > ul > li.active {
    height: 100%;
    opacity: 1;
  }

  .main-nav > ul > li > a:hover:before,
  .main-nav > ul > li:hover > a:before,
  .main-nav > ul > li > a.active:before {
    height: 100%;
    opacity: 1;
  }

  .main-nav > ul > li.active.menu-item-has-children > a:after,
  .main-nav > ul > li.menu-item-has-children > a:hover:after,
  .main-nav > ul > li.menu-item-has-children:hover > a:after {
    opacity: 0;
  }


  /* FIRST SUB-LEVEL */
  .main-nav ul ul.sub-menu li {
    position: relative;
    margin: 10px 0;
    display: flex;
    justify-content: center;
  }

  .main-nav ul ul.sub-menu ul {
    margin-top: 0;
    top: 100%;
  }

  .main-nav ul ul.sub-menu li a:not(:only-child):after {
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    height: 2px;
    margin-top: -1px;
    opacity: .6;
    position: absolute;
    right: 10px;
    top: 50%;
    width: 10px;
  }

  .main-nav ul ul.sub-menu li a {
    color: #faf800;
    font-size: 1rem;
    font-weight: 600;
    padding: 10px 20px;
    position: relative;
    text-transform: uppercase;
  }

  .main-nav ul ul.sub-menu li a[title="separator"] {
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.05) !important;
    height: 5px;
    padding: 0;
    text-indent: -2999px;
  }

  .main-nav ul ul.sub-menu li a:after {
    display: block;
    content: '';
    border-bottom: solid 2px #faf800;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 100% 50%;
  }

  .main-nav ul ul.sub-menu li.active > a,
  .main-nav ul ul.sub-menu li a:hover:after {
    color: #fe0102;
    transform: scaleX(1);
    transform-origin: 0 50%;
  }

  /* REST OF SUB-LEVELS */
  .main-nav ul ul.sub-menu,
  .main-nav .hg_mega_container {
    background: #000000;
    background: rgba(0, 0, 0, 0.8);
    padding: 6px 0;
    position: absolute;
    width: 50%;
    border-radius: 6px;
    backdrop-filter: blur(10px);
  }

  .main-nav ul ul.sub-menu li:hover > ul {
    left: 100%;
    opacity: 1;
    top: -3px;
  }

  /* MEGAMENU OVERRIDES */
  .main-nav .hg_mega_container a.zn_mega_title[href="#custom-invisible"],
  .main-nav .hg_mega_container li:last-child a.zn_mega_title[href="#custom-invisible"] {
    color: transparent;
  }

  .main-nav .hg_mega_container {
    background: #f5f5f5;
    background: rgba(245, 245, 245, 0.95);
    border: 1px solid rgba(255, 255, 255, .8);
    box-shadow: 0 0 4px rgba(0, 0, 0, .4);
    margin-top: 10px;
    padding: 6px 0;
    padding: 20px 0;
    position: absolute;
  }

  .main-nav .hg_mega_container:before {
    content: '';
    display: block;
    height: 10px;
    left: 0;
    position: absolute;
    top: -10px;
    width: 100%;
  }

  /* fix for top hover */
  .main-nav .hg_mega_container li a {
    color: #757575;
    font-size: 13px;
    padding: 10px 0;
    position: relative;
    text-transform: uppercase;
  }

  .main-nav .hg_mega_container li a:not(.zn_mega_title):hover,
  .main-nav .hg_mega_container li.active > a:not(.zn_mega_title) {
    color: #323232;
  }

  .main-nav .hg_mega_container li a:not(.zn_mega_title):before {
    background-color: #fd0000;
    background-color: var(--tertiary-color);
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    transition: width .15s cubic-bezier(0.680, 0, 0.265, 1);
    width: 0;
  }

  .main-nav .hg_mega_container li a:not(.zn_mega_title):hover:before,
  .main-nav .hg_mega_container li.active > a:not(.zn_mega_title):before {
    width: 100%;
  }

  .main-nav .hg_mega_container .zn_mega_title_hide {
    display: none;
  }

  .main-nav .hg_mega_container .zn_mega_title_hide.v-hidden {
    display: block;
    visibility: hidden;
  }

  .main-nav .hg_mega_container li a.zn_mega_title,
  .main-nav div.hg_mega_container ul li:last-child > a.zn_mega_title {
    border-bottom-color: rgba(0, 0, 0, 0.1);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    color: #333;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
    padding: 12px 0px;
    padding-left: 0;
    padding-right: 0;
    text-transform: uppercase;
  }

  .main-nav .menu-item-spacer {
    display: inline-block;
    height: 25px;
    width: 100%;
  }

  /* ANIMATIONS */
  .main-nav ul ul.sub-menu {
    opacity: 0;
    visibility: hidden;
    width: 268px;
    top: 75%;
    left: 50%;
    line-height: 1.3rem;
    text-align: center;
    transition: transform .4s, opacity .2s, visibility 0s .2s;
    transform: translateY(25px) translateX(-50%);
  }


  .main-nav ul li:hover > ul {
    opacity: 1;
    visibility: visible;
    z-index: 100;
    transition: transform .4s, opacity .2s, visibility 0s 0s;
    transform: translateY(0px) translateX(-50%);
  }

  .main-nav ul li:hover > .hg_mega_container {
    margin-top: 10px;
  }

  .main-nav ul ul li a {
    transition: padding-left .35s ease, border-right .35s ease;
  }

  /* MENU BADGES */
  .main-nav ul .hg-mega-new-item {
    background-color: #fd0000;
    background-color: var(--tertiary-color);
    border-radius: 2px;
    color: #fff;
    font-size: 10px;
    line-height: 1;
    padding: 5px;
    position: absolute;
    right: 3px;
    text-transform: none;
    text-transform: initial;
    top: 50%;
    transform: translateY(-50%);
  }

  .main-nav ul ul .hg-mega-new-item {
    left: auto;
    right: 10px;
  }

  .main-nav ul ul.sub-menu li a:not(:only-child) .hg-mega-new-item {
    right: 23px;
  }

  .main-nav .hg_mega_container ul .hg-mega-new-item {
    right: 3px;
  }

  #menu-main-menu li > a[href="#"] {
    cursor: default;
  }

}

@media (max-width: 1199.9px) {
  .main-menu-wrapper {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 992px) {
  .heroText {
    width: 100%;
    padding-right: inherit;
    color: #fff;
    text-shadow: 2px 2px 4px #00000069;
    text-align: center;
    margin-bottom: 2rem;
  }
  .heroBg {
    min-height: 150px;

    video {
      width:120%
    }
  }
  .subpage-title {
    justify-content: center;
    text-align: center;
  }
}

/* Responsive Menu */
#zn-res-menuwrapper {
  display: none
}

#zn-res-menu ul {
  position: fixed !important;
}

#zn-res-menu, #zn-res-menu li, #zn-res-menu ul {
  list-style: none;
  margin: 0;
  padding: 0
}

#zn-res-menu {
  box-shadow: 0 0 10px -4px #000;
  overflow: hidden;
}

#zn-res-menu * {
  -webkit-backface-visibility: hidden
}

.zn_res_menu_visible #zn-res-menu,
.zn_res_menu_visible #zn-res-menu li ul {
  display: none
}

#zn-res-menu, #zn-res-menu li ul {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: rgba(0, 0, 0, .9);
  backdrop-filter: blur(10px);
  display: none;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  overflow-scrolling: touch;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(110%, 0, 0);
  transform-style: preserve-3d;
  transition: transform .5s ease;
  width: 100%;
  z-index: 11112
}

#zn-res-menu ul.zn-menu-visible,
#zn-res-menu.zn-menu-visible {
  transform: translate3d(0, 0, 0)
}

#zn-res-menu li {
  max-width: 100%;
  position: relative;
  width: 100%;
}

#zn-res-menu .zn_mega_container {
  width: 100%
}

#zn-res-menu .zn_mega_container li {
  float: none;
  width: 100%
}

#zn-res-menu li > a {
  color: #f5f5f5;
  display: block;
  letter-spacing: .5px;
  padding: 13px 28px;
  text-transform: uppercase;
  z-index: 1;
}

#zn-res-menu li.active > a {
  color: #333
}

#zn-res-menu a {
  cursor: pointer
}

#zn-res-menu .zn_res_has_submenu > span {
  color: #f5f5f5;
  cursor: pointer;
  height: 44px;
  line-height: 49px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  vertical-align: middle;
  width: 50px;
  z-index: 2;
}

#zn-res-menu .zn_res_menu_go_back > a {
  display: inline-block;
  text-align: center;
  width: 100%;
}

.zn_res_menu_go_back a svg {
  font-size: 1.2rem;
  margin-right: 12px;
  vertical-align: sub;
}

.zn-res-menuwrapper {
  display: block;
}

.zn-res-trigger {
  display: block;
  height: 28px;
  margin: 10px;
  position: relative;
  width: 25px;
}

#zn-res-menu .zn_res_has_submenu > span::before,
#zn-res-menu .zn_res_menu_go_back > span::before {
  display: none;
}

.zn-res-trigger::after {
  background: #414141;
  background: var(--gray);
  border-radius: 4px;
  box-shadow: 0 8px 0 #414141, 0 16px 0 #414141;
  box-shadow: 0 8px 0 var(--gray), 0 16px 0 var(--gray);
  content: '';
  height: 4px;
  left: 0;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  width: 25px
}

#zn-res-menu li > a[title=separator], .zn_res_has_submenu .new-item {
  display: none;
}

#zn-res-menu.zn-menu-visible .zn-mega-new-item {
  display: none;
}

.mobile_menu_phone {
  border-top: 2px solid #faf800;
  border-top: 2px solid var(--primary-color);
}

.mobile_menu_phone svg {
  margin-right: 18px;
}

/* Menu Overlay */
.zn-res-menu-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity .4s ease-out, visibility .4s ease-out;
  visibility: hidden;
  width: 100%;
  z-index: 11111;
}

.zn-menu-visible + .zn-res-menu-overlay {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 1199.9px) {
  #zn-res-menuwrapper,
  .zn_res_menu_visible #zn-res-menu,
  .zn_res_menu_visible #zn-res-menu li ul {
    display: block;
  }

  .site-header-main-left {
    order: 0
  }

  .site-header-main-center {
    order: 2
  }

  .site-header-main-right {
    order: 1
  }

  #main-menu {
    display: none;
  }

  #zn-res-menu li.active {
    background-color: #faf800;
    background-color: var(--primary-color);
  }

  #zn-res-menu li.active > li {
    background-color: #faf800;
    background-color: var(--primary-color);
  }

  #main-menu .dropdown-menu {
    margin-left: -20px;
    padding-left: 10px;
  }

  #main-menu .dropdown-menu {
    border: none;
    box-shadow: none;
  }

  #main-menu .dropdown-menu li > a {
    font-size: 12px;
    padding: 3px 8px;
  }

  #main-menu .dropdown-menu li > a:hover {
    background-color: transparent;
  }

  #main-menu .dropdown-menu > .active > a,
  #main-menu .dropdown-menu > .active > a:hover,
  #main-menu .dropdown-menu > .active > a:focus {
    background-color: transparent;
  }

  #main-menu .dropdown-menu li.active > .inner-elem {
    background-color: #fd0000;
    background-color: var(--tertiary-color);
  }

  #main-menu > ul {
    -webkit-backface-visibility: hidden;
    transition: left 0.2s ease-out;
  }

  #main-menu > ul ul {
    background: transparent;
    opacity: 1 !important;
    position: static;
    visibility: visible !important;
  }

  #main-menu > ul li {
    float: none;
    margin-left: 0;
    padding: 5px 0;
    padding-left: 0;
    width: 100%;
  }

  #zn-res-menu .zn-close-menu-button {
    border-left: solid 1px #ccc;
    display: inline-block;
    height: 45px;
    padding: 0 !important;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px !important;
    z-index: 11111;
  }

  #zn-res-menu .zn-close-menu-button svg {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    position: static;
  }
  .navbar-fixed-top {
    margin-bottom: 20px;
  }
  .navbar-fixed-bottom {
    margin-top: 20px;
  }
  .navbar-fixed-top .navbar-inner,
  .navbar-fixed-bottom .navbar-inner {
    padding: 5px;
  }
  .navbar .container {
    padding: 0;
    width: auto;
  }
  .navbar .brand {
    margin: 0 0 0 -5px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .nav-collapse {
    clear: both;
  }
  .nav-collapse .nav {
    float: none;
    margin: 0 0 10px;
  }
  .nav-collapse .nav > li {
    float: none;
  }
  .nav-collapse .nav > li > a {
    margin-bottom: 2px;
  }
  .nav-collapse .nav .nav-header {
    color: #777777;
    text-shadow: none;
  }
  .nav-collapse .nav > li > a,
  .nav-collapse .dropdown-menu a {
    border-radius: 3px;
    color: #777777;
    font-weight: bold;
    padding: 9px 15px;
  }
  .nav-collapse .btn {
    border-radius: 4px;
    font-weight: normal;
    padding: 4px 10px 4px;
  }
  .nav-collapse .dropdown-menu li + li a {
    margin-bottom: 2px;
  }
  .nav-collapse .nav > li > a:hover,
  .nav-collapse .nav > li > a:focus,
  .nav-collapse .dropdown-menu a:hover,
  .nav-collapse .dropdown-menu a:focus {
    background-color: #f2f2f2;
  }
  .navbar-inverse .nav-collapse .nav > li > a,
  .navbar-inverse .nav-collapse .dropdown-menu a {
    color: #999999;
  }
  .navbar-inverse .nav-collapse .nav > li > a:hover,
  .navbar-inverse .nav-collapse .nav > li > a:focus,
  .navbar-inverse .nav-collapse .dropdown-menu a:hover,
  .navbar-inverse .nav-collapse .dropdown-menu a:focus {
    background-color: #111111;
  }
  .nav-collapse.in .btn-group {
    margin-top: 5px;
    padding: 0;
  }
  .nav-collapse .dropdown-menu {
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    display: none;
    float: none;
    left: auto;
    margin: 0 15px;
    max-width: none;
    padding: 0;
    position: static;
    top: auto;
  }
  .nav-collapse .open > .dropdown-menu {
    display: block;
  }
  .nav-collapse .dropdown-menu:before,
  .nav-collapse .dropdown-menu:after {
    display: none;
  }
  .nav-collapse .nav > li > .dropdown-menu:before,
  .nav-collapse .nav > li > .dropdown-menu:after {
    display: none;
  }
  .nav-collapse .navbar-form,
  .nav-collapse .navbar-search {
    border-bottom: 1px solid #f2f2f2;
    border-top: 1px solid #f2f2f2;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
    float: none;
    margin: 10px 0;
    padding: 10px 15px;
  }
  .navbar-inverse .nav-collapse .navbar-form,
  .navbar-inverse .nav-collapse .navbar-search {
    border-bottom-color: #111111;
    border-top-color: #111111;
  }
  .navbar .nav-collapse .nav.pull-right {
    float: none;
    margin-left: 0;
  }
  .nav-collapse,
  .nav-collapse.collapse {
    height: 0;
    overflow: hidden;
  }
  .navbar .btn-navbar {
    display: block;
  }
  .navbar-static .navbar-inner {
    padding-left: 10px;
    padding-right: 10px;
  }

  .site-header[data-header-style="8"] .siteheader-container {
    margin-top: 0 !important;
  }

  .graphicElement {
    bottom: 0;
    position: absolute;
    right: -77px;
    z-index: -3;
  }
  .graphicElement img {
    height: 100%;
  }
  .couponContainer .coupon {
    margin-bottom: 22px;
    justify-content: center;
  }
  .couponContainer .gridImage {
    text-align: center;
  }
  .flex-wrap-sm {
    flex-wrap: wrap
  }
  .imgBoxContainer {
    margin: 0 auto 71px;
    margin-bottom: 35px;
    max-width: 100%;
    width: 400px;
  }
  .imgBoxContainer:last-child {
    margin-bottom: 0;
  }
  .imgBox_3d {
    margin: auto;
    max-width: 100%;
    width: 500px;
  }
  .heroContent {
    padding: 13% 0;
    height: unset;
  }
  .scheduleNow {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .heroContent {
    flex-wrap: wrap;
    padding-top: 0;
  }
  .heroText {
    font-size: 2rem;
  }
}

@media (max-width: 991.9px) and (min-width: 768px) {
  .graphicElement {
    height: 100%;
  }
  .iconBoxText {
    font-size: 1.5rem
  }
  .heroText {
    font-size: 1.5rem;
  }
  .vanBannerTag {
    height: 50px;
    margin-left: 20px;
    padding: 13px;
    transform: translateY(-30px);
  }
  .vanBannerTag:before {
    border-width: 25px;
  }
  .vanBannerTag:after {
    transform: skewX(-45deg) translateX(-50px);
    width: 25px;
  }
  .vanBannerVan {
    right: -30px;
    top: -73px;
    width: 40%;
  }
  .vanBannerTag h4 {
    font-size: 1.2rem;
    font-size: clamp(.8rem, 1.7vw, 2.5rem);
    font-weight: 400;
    letter-spacing: .2px;
    font-weight: 600;
  }
}

@media (max-width: 767.9px) {
  body {
    font-size: 14px
  }
  .heroBg {
    height: auto;
  }
  .graphicElement {
    height: 70%;
  }
  .quote-ribbon {
    transform: translateY(-251px);
  }
  .vanBannerTag {
    height: 50px;
    margin-left: -25px;
    transform: translateY(-30px);
  }
  .vanBannerTag:before {
    border-width: 25px;
  }
  .vanBannerTag:after {
    transform: skewX(-45deg) translateX(-50px);
    width: 25px;
  }
  .vanBannerVan {
    bottom: 7px;
    max-width: 45%;
    right: -3px;
    top: unset;
    width: 233px;
  }
  .vanBannerTag h4 {
    font-size: .7rem;
    font-size: clamp(.8rem, 1.7vw, 2.5rem);
    letter-spacing: 0;
    font-weight: 500;
  }
  .sectionTitle h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
  .sectionTitle span {
    font-size: 1.7rem;
  }
  .couponDetails {
    font-size: .5rem;
    margin-top: 8px;
  }
  .couponLeft {
    padding: 10px 30px 10px 10px;
  }
  .couponRight {
    padding: 10px 10px 10px 0;
  }
  .couponName {
    font-size: 1.5rem;
  }
  .subtext {
    font-size: .7rem;
  }
  .iconBox {
    margin-bottom: 30px;
  }
  .iconBox:last-child {
    margin-bottom: 0
  }
}

@media (min-width: 481px) and (max-width: 599.9px) {

  .site-header .site-logo-img {
    max-height: 80px
  }

  .headerphone {
    font-size: 20px;
  }

}

@media (max-width: 768px) {
  .headerphone {
    display: none;
  }
}

@media (max-width: 480px) {
  .vanBannerVan {
    bottom: 24px;
  }
}

@media (max-width: 991px) {
  .sidebar-bear {
    display: none;
  }
  .sidebar .widget {
    bottom: unset;
  }
}

ul.menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget .menu li a {
  color: #414141;
  color: var(--gray);
  font-size: 16px;
}

.widget a {
  color: #000000;
}

.menu li {
  border-bottom: 1px solid #414141;
  border-bottom: 1px solid var(--gray);
  font-weight: 500;
  padding: 10px 0;
  position: relative;
}

.inner-content li {
  margin-bottom: 7px;
}

.inner-content ul a {
  font-weight: 700;
  padding-bottom: 4px;
}

.menu li:last-child {
  border-bottom: none;
}

.widget .menu li a:hover {
  color: #fd0000;
  color: var(--tertiary-color);
  transition: all .1s ease-in-out;
}

.subpage-title .breadCrumb li {
  color: #fff;
  text-shadow: 2px 2px 3px #000;
  cursor: default;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  margin-right: 7px;
  margin-top: 11px;
  padding-right: 7px;
  position: relative;
  text-transform: uppercase;
}

.subpage-title .breadCrumb li a {
  color: #fff;
  text-shadow: 2px 2px 3px #000;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 24px;
  transition: color .3s;
}

.subpage-title .breadCrumb li:after {
  color: #fff;
  text-shadow: 2px 2px 3px #000;
  content: '/';
  font-weight: 700;
  position: absolute;
  right: -5px;
  top: 0px;
}

.subpage-title .breadCrumb li:last-child::after {
  display: none;
}

.subpage-title .breadCrumb li:last-child {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 24px;
  margin-right: 0px;
  padding-right: 0px;
}

ul.breadCrumb {
  padding-left: 0;
  margin-bottom: 0;
}

.subpage-title .breadCrumb li a:hover {
  color: #fd0000;
  color: var(--tertiary-color);
}

.inner-content h2 {
  color: #414141;
  color: var(--gray);
  font-size: 1.6rem;
  font-weight: 500;
}

.inner-content h3 {
  color: #000000;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 25px 0 15px;
}

.inner-content h5 {
  color: #414141;
  color: var(--gray);
  font-weight: 500;
}

.btn {
  display: inline-block;
  font-weight: 600;
  padding: 17px 27px;
  position: relative;
  transition: color .3s, background .3s, border .3s;
}

.btn--solid {
  border: 2px solid #31409b;
  border: 2px solid var(--secondary-color);
  background-color: #31409b;
  background: var(--secondary-color);
  color: #ffffff;
}

.btn--solid:hover {
  background-color: transparent;
  border: 2px solid #31409b;
  border: 2px solid var(--secondary-color);
  color: #31409b;
  color: var(--secondary-color);
}

.number-list {
  list-style: decimal;
}

.full-list li:before {
  display: none;
}

.full-list svg {
  color: #fd0000;
  color: var(--tertiary-color);
  margin-right: 10px;
}

ul.full-list {
  padding-left: 0;
  list-style: none;
}

.half-list {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}

.half-list li {
  padding-right: 20px;
  width: 50%;
  list-style: none;
}

.half-list li:before {
  display: none;
}

.half-list svg {
  color: #fd0000;
  color: var(--tertiary-color);
  margin-right: 10px;
}

.team-section {
  padding: 50px 0 0;
  position: relative;
}

.team-section .team-block {
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.team-section .team-block .team-img {
  position: relative;
}

.team-section .team-block .team-img img {
  display: block;
  margin: auto;
  width: 100%;
}

.team-section .team-block .team-content {
  -ms-box-shadow: 5px 0 30px rgba(237, 237, 237, .75);
  -o-box-shadow: 5px 0 30px rgba(237, 237, 237, .75);
  box-shadow: 5px 0 30px rgba(237, 237, 237, .75);
  padding: 25px 15px 20px;
  position: relative;
  text-align: center;
}

.appForm {
  display: inline-block;
}

.app-details {
  color: #000000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 0;
  text-align: left;
}

.btn--solidColor {
  background: #31409b;
  background: var(--secondary-color);
  border: 2px solid #31409b;
  border: 2px solid var(--secondary-color);
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .5px;
  line-height: 24px;
  margin-bottom: 15px;
  padding: 15px 38px;
  position: relative;
  text-transform: uppercase;
}

.btn--solidColor:hover {
  background: #fd0000;
  background: var(--tertiary-color);
  border-color: #fd0000;
  border-color: var(--tertiary-color);
  color: #ffffff;
}

.review {
  padding: 30px 0;
}

.reviewQuote {
  padding: 25px;
  font-size: 1.2rem;
}

.reviewAuthor {
  font-weight: 700;
  font-size: 1.3rem;
}

.fiveStars {
  width: 150px;
}

@media (max-width: 767.9px) {
  .subpage-title span {
    font-size: 2.3rem;
  }
  .subpage-title .breadCrumb li a {
    font-size: 1rem;
  }
  .subpage-title .breadCrumb li:last-child {
    font-size: 1rem;
  }
}

.contentImg img {
  margin: 30px 0;
  box-shadow: 0 0 10px #00000021, 15px 15px var(--secondary-color);
}

@media (max-width: 1199px) {
  .ruvtbul#bbblink {
    margin: auto !important;
  }
}

.frhzbum {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 25px;
  text-align: center;
  display: block;
  bottom: 100px;
}

.frhzbum:first-child {
  padding-top: 70px;
}

@media (max-width: 991.9px) {
  .frhzbum {
    position: unset;
    bottom: unset;
  }
}

@media (max-width: 767.9px) {
  .graphicElement img {
    display: none;
  }
}

.repLogo img {
  padding: 20px 0;
  width: 220px;
}


.badger-accordion {
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 5em;
}

.js-badger-accordion-header button {
  color: var(--secondary-color);
}

.badger-accordion__trigger {
  align-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border: 0;
  border-radius: 0px;
  display: flex;
  font-size: 1.25rem;
  line-height: 1;
  padding: 20px;
  text-align: left;
  transition: all ease-in-out .2s;
  width: 100%
}

.badger-accordion__trigger:focus, .badger-accordion__trigger:hover {
  cursor: pointer;
  outline: none
}

.badger-accordion__trigger:focus .badger-accordion__trigger-title, .badger-accordion__trigger:hover .badger-accordion__trigger-title, .badger-accordion__trigger:focus .badger-accordion__trigger-icon svg, .badger-accordion__trigger:hover .badger-accordion__trigger-icon svg {
  color: #ed1c24
}

.badger-accordion__trigger:focus .badger-accordion__trigger-icon:after, .badger-accordion__trigger:focus .badger-accordion__trigger-icon:before, .badger-accordion__trigger:hover .badger-accordion__trigger-icon:after, .badger-accordion__trigger:hover .badger-accordion__trigger-icon:before {
  background-color: #ed1c24
}

.badger-accordion__trigger::-moz-focus-inner {
  border: none
}

.badger-accordion__trigger-title {
  font-size: 1.2rem;
  transition: ease-in-out .3s
}

.badger-accordion__trigger-icon {
  display: block;
  margin-right: 20px;
  position: relative;
  transition: all ease-in-out .2s;
}

.badger-accordion__trigger-icon svg {
  transition: ease-in-out .3s;
  font-size: 1.6em;
}

.badger-accordion__trigger-icon:after {
  color: #ed1c24;
  position: absolute;
  top: 10px;
  transition: all ease-in-out .1333333333s;
  content: "\f0da";
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;

}

.badger-accordion__trigger-icon:after {
  display: none;
}

.badger-accordion__panel {
  position: relative
}

.badger-accordion__panel:after {
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  width: 100%
}

.badger-accordion__panel-inner {
  padding: 20px 20px 40px
}

.badger-accordion__panel {
  overflow: hidden;
}

.badger-accordion__panel.-ba-is-hidden {
  max-height: 0 !important;
  visibility: hidden;
}

.badger-accordion--initialized .badger-accordion__panel {
  transition: all ease-in-out 0.5s;
}

span.badger-accordion__trigger-title {
  font-size: 2.1rem;
}

.badger-accordion__panel-inner.text-module.js-badger-accordion-panel-inner {
  border-left: 5px solid var(--gray);
  margin-left: 40px;
}

.-ba-is-active .badger-accordion__trigger-icon:after {
  transform: rotate(0deg) translate3d(7px, 10px, 0);
}

@media (max-width: 767px) {
  .badger-accordion__panel-inner.text-module.js-badger-accordion-panel-inner {
    margin-left: 30px;
  }
}

.logoShadow img {
  filter: drop-shadow(0px 0px 2px #eee);
}

.reviewButton {
  width: 300px;
  box-shadow: rgba(0, 0, 0, .35) 0 5px 15px;
  -webkit-transition: -webkit-transform .45s cubic-bezier(0.25, 1, 0.33, 1);
  -moz-transition: -moz-transform .45s cubic-bezier(0.25, 1, 0.33, 1);
  -o-transition: -o-transform .45s cubic-bezier(0.25, 1, 0.33, 1);
  transition: transform .45s cubic-bezier(0.25, 1, 0.33, 1);
}

.reviewButton:hover {
  transform: translate(10px, -10px);
}

.testimonialElement {
  position: absolute;
  right: 100px;
  bottom: 30px;
  z-index: -1;
  width: 500px;
}

.grecaptcha-badge {
  visibility: visible !important;
}

@media (min-width: 991px) {
  .hero .mobile {
    display: none;
  }
  .heroContentContainer {
    justify-content: end;
  }
}

@media (max-width: 991px) {
  .hero .desktop {
    display: none;
  }
  .heroBgOverlay:before {
    background: rgba(0, 0, 0, .2);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}

.applyBtn {
  margin: 15px;
  float: right;
}

@media (max-width: 768px) {
  .applyBtn {
    float: unset;
  }
}

@media (max-width: 480px) {
  .applyBtn {
    margin: 25px 0;
    display: block;
  }
}